<template>
  <div class="container">
    <div class="row" v-if="order != null">
      <div class="col-md-8 mx-auto">
        <div class="card border-0">
          <div class="card-body">
            <span class="h4">Record an external payment</span>
            <h5 class="mt-1">
              <small>(Order #{{ order.id }} - {{order.currency.symbol}}{{ total.toFixed(2) }})</small>
            </h5>
            <p>{{ order.type | paymentType }}</p>

            <form role="form" @submit.prevent="processPayment">
              <!-- Cardholder's Name -->
              <div class="row mb-4">
                <div class="col">
                  <p class="alert alert-primary">
                    <i class="fa fa-info-circle me-1"></i>
                    External payments allows you to record payments that were
                    made outside of Swandoola. For example, cash payments from
                    clients or direct bank transfers.
                  </p>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-6 mx-auto">
                  <label>Payment Amount</label>
                  <div
                    class="form-check mb-2"
                    v-if="unpaidInstalments.length > 1"
                    v-for="instalment in unpaidInstalments"
                  >
                    <input
                      class="form-check-input"
                      name="paymentType"
                      type="radio"
                      v-model="paymentInstalment"
                      :value="instalment.id"
                      required
                    />
                    <label class="form-check-label">
                      Pay instalment: {{order.currency.symbol}}{{ instalment.total | currencyFormat }}
                    </label>
                  </div>
                  <div class="form-check mb-2">
                    <input
                      class="form-check-input"
                      name="paymentType"
                      type="radio"
                      v-model="paymentInstalment"
                      value="remaining"
                      required
                    />
                    <label class="form-check-label text-start">
                      Pay total outstanding amount: {{order.currency.symbol}}{{
                        order.amount_outstanding | currencyFormat
                      }}
                    </label>
                  </div>
                  <div class="form-check mb-2">
                    <input
                        class="form-check-input"
                        name="paymentType"
                        type="radio"
                        v-model="paymentInstalment"
                        value="other"
                        required
                    />
                    <label class="form-check-label">
                      Other amount
                    </label>
                    <currency-input
                        v-if="paymentInstalment === 'other'"
                        locale="gb"
                        class="form-control mb-3"
                        currency="GBP"
                        :min="0"
                        :max="order.amount_outstanding"
                        v-model="paymentAmount"></currency-input>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-6 mx-auto">
                  <label for="paymentMethod">External Payment Method:</label>
                  <div class="d-block">
                    <input
                      type="radio"
                      name="paymentMethod"
                      id="cash"
                      value="cash"
                      v-model="paymentMethod"
                      required
                    />
                    <label for="cash" class="ms-1 me-1">Cash</label><br />
                    <input
                      type="radio"
                      name="paymentMethod"
                      id="bank_transfer"
                      value="bank_transfer"
                      v-model="paymentMethod"
                      required
                    />
                    <label for="bank_transfer" class="ms-1 me-1"
                      >Bank Transfer</label
                    ><br />
                    <input
                      type="radio"
                      name="paymentMethod"
                      id="other"
                      value="other"
                      v-model="paymentMethod"
                      required
                    />
                    <label for="other" class="ms-1 me-1">Other</label><br />
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-6 mx-auto">
                  <label for="name">Payment Reference:</label>
                  <input
                    type="text"
                    class="form-control"
                    name="name"
                    v-model="name"
                    placeholder="Optional"
                  />
                </div>
              </div>

              <!-- Payment Button -->
              <div class="row">
                <div class="col-6 mx-auto text-center">
                  <input
                    v-if="!busy"
                    type="submit"
                    class="btn btn-primary"
                    :disabled="busy"
                    :value="'Record External Payment'"
                  />
                  <busy :visible="busy">Processing Payment</busy>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CurrencyInput } from "vue-currency-input";
export default {
  data() {
    return {
      order: null,
      busy: false,
      name: null,
      total: 0,
      paymentMethod: "cash",
      paymentInstalment: null,
      paymentAmount: 0,
    };
  },

  mounted() {
    this.fetchOrder();
  },

  methods: {
    processPayment() {
      this.busy = true;
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/orders/" +
            this.order.id +
            "/payment-cash",
          {
            name_on_card: this.name,
            payment_method: this.paymentMethod,
            instalment: this.paymentInstalment,
            other_amount: this.paymentAmount,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          if (data.color !== 'danger') {
            setTimeout(() => {
              this.busy = false;
              window.location = "/orders/" + this.order.id;
            }, 2000);
          } else {
            this.busy = false;
          }
        });
    },
    fetchOrder() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL + "/orders/api/" + this.$route.params.id
        )
        .then(({ data }) => {
          this.order = data;
          this.total = data.total;
        });
    },
  },
  filters: {
    formatMoney(n, c, d, t) {
      var c = isNaN((c = Math.abs(c))) ? 2 : c,
        d = d == undefined ? "." : d,
        t = t == undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
        j = (j = i.length) > 3 ? j % 3 : 0;

      return (
        s +
        (j ? i.substr(0, j) + t : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
        (c
          ? d +
            Math.abs(n - i)
              .toFixed(c)
              .slice(2)
          : "")
      );
    },
    formatText(str) {
      return str.replace(/_/g, " ");
    },
    formatDate(date) {
      return moment(date).format("Do MMM YYYY");
    },
    formatUnderScored(text) {
      return text.replace(/_/g, " ");
    },
    paymentType(type) {
      if (type == "payable_sup_client") {
        return "Payment from Client to Supplier";
      } else if (type == "payable_sup_me") {
        return "Payment from Me to Supplier";
      } else if (type == "custom_order") {
        return "Payment from Client to Me";
      }
    },

    currencyFormat(val) {
      return (Math.round(val * 100) / 100).toFixed(2);
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    unpaidInstalments() {
      return this.order.instalments.filter((instalment) => {
        return instalment.status === "not_paid";
      });
    },
  },
  components: {
    CurrencyInput
  }
};
</script>

<style>
</style>
